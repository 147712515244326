// mat-dialog-container {
//   position: relative;
// }

// mat-card {
//   box-sizing: border-box;
// }

// // Scrollbar (del)
// //::-webkit-scrollbar { width: 0; }

// .cdk-overlay-container {
//   ::-webkit-scrollbar {
//     width: 5px !important;
//   }

//   ::-webkit-scrollbar-track {
//     border-radius: 10px;
//   }

//   ::-webkit-scrollbar-thumb {
//     border-radius: 10px;
//     background-color: grey;
//     opacity: 0.6;
//   }
// }

// @media all and (min-width: 1400px) {
//   .modal-window {
//     width: 60%;
//   }
// }

// @media all and (min-width: 1600px) {
//   .modal-window {
//     width: 50%;
//   }
// }

// mat-progress-bar.card-sending {
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   z-index: 99;
//   height: 3px;
// }

// mat-card.mat-card,
// .mat-accordion .mat-expansion-panel {
//   border-radius: 0 !important;
// }

// mat-card.mat-card mat-card-actions button:last-child {
//   margin-right: 0;
// }

a[href] {
  color: unset;
  text-decoration: none;
}

// button[mat-flat-button] {
//   border-radius: 0;
// }

// div.mat-card-image {
//   margin-bottom: 0;
// }

.mat-mdc-unelevated-button {
  height: unset !important;
  min-height: var(--mdc-filled-button-container-height);
}

mat-card-header {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
